<template>
  <div
    class="tickets-list-item"
    :class="{
      'select-mode': selectMode,
      'is-disabled': (selectMode && !canSelect) || isDisabled,
    }"
    @click="onSelect"
  >
    <div class="ticket-main">
      <!-- icons -->
      <div class="status-icon">
        <template v-if="!selectMode">
          <img
            v-if="!ticket.processing"
            :src="require('@/assets/ticket_icon.svg')"
            alt="ticket"
          />
          <v-progress-circular
            v-if="ticket.processing"
            color="primary"
            :size="22"
            :width="2"
            indeterminate
          />
        </template>
        <template v-if="selectMode">
          <span class="material-icons" v-if="ticket.selected && canSelect">
            check_circle
          </span>
          <span class="material-icons" v-if="!ticket.selected && canSelect">
            radio_button_unchecked
          </span>
          <span class="material-icons" v-if="!canSelect"> block </span>
        </template>
      </div>

      <div class="ticket-data-grid">
        <div class="ticket-attendee">
          <div
            class="ticket-status"
            :class="{
              'is-claimed':
                (!isDisabled && ticket.is_claimed) || ticket.processing,
              'is-unclaimed':
                !isDisabled && !ticket.is_claimed && !ticket.processing,
            }"
          >
            <span v-if="ticket.is_purchased_by_me">{{
              $t("order") + ` #${ticket.order_number} · `
            }}</span>
            <span>{{ ticketStatus }}</span>
          </div>
          <div class="ticket-title" :class="{ 'is-dimmed': showDefaultTitle }">
            {{ ticketTitle }}
          </div>
        </div>
        <div class="ticket-event-info">
          <div class="event-reg-chose">{{ ticketRegChose }}</div>
          <div class="event-start-time" v-if="showStartTime">
            {{ ticket.start_time }}
          </div>
        </div>
        <div class="ticket-buttons">
          <template v-if="ticketButton === 'add' || ticketButton === 'more'">
            <!-- context menu -->
            <div class="context-menu">
              <v-menu
                transition="slide-y-transition"
                bottom
                left
                nudge-right="5"
                nudge-top="5"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <mem-button v-if="ticketButton === 'add'">{{
                      $t("add_athlete")
                    }}</mem-button>
                    <div v-if="ticketButton === 'more'" class="btn-more">
                      <span class="material-icons"> more_horiz </span>
                    </div>
                  </div>
                </template>
                <div class="context-menu-body">
                  <!-- add athlete -->
                  <template v-if="!ticket.is_claimed">
                    <div
                      class="menu-item"
                      v-for="(item, index) in unclaimedTicketMenu"
                      :key="index"
                      @click="onUnclaimedActionClick(item.type)"
                    >
                      <div class="item-icon-wrapper">
                        <span class="material-icons">{{ item.icon }}</span>
                      </div>
                      <span class="item-name">{{ $t(item.text) }}</span>
                    </div>
                  </template>
                  <!-- change ticket -->
                  <template v-if="ticket.is_claimed">
                    <div
                      class="menu-item"
                      v-for="(item, index) in claimedTicketMenu"
                      :key="index"
                      @click="onClaimedActionClick(item.type)"
                    >
                      <div class="item-icon-wrapper">
                        <span class="material-icons">{{ item.icon }}</span>
                      </div>
                      <span class="item-name">{{ $t(item.text) }}</span>
                    </div>
                  </template>
                </div>
              </v-menu>
            </div>
            <!-- dialog menu -->
            <div class="dialog-menu">
              <v-dialog
                class="mem-dialog"
                :max-width="410"
                overlay-color="#000000"
                overlay-opacity="0.54"
                transition="slide-y-reverse-transition"
                v-model="dialogMenu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <mem-button v-if="ticketButton === 'add'">{{
                      $t("add_athlete")
                    }}</mem-button>
                    <span v-if="ticketButton === 'more'" class="material-icons">
                      more_horiz
                    </span>
                  </div>
                </template>
                <div class="dialog-menu-body">
                  <!-- add athlete -->
                  <template v-if="!ticket.is_claimed">
                    <div
                      class="dialog-menu-item"
                      v-for="(item, index) in unclaimedTicketMenu"
                      :key="index"
                      @click="onUnclaimedActionClick(item.type)"
                    >
                      <span class="material-icons"> {{ item.icon }} </span>
                      <span>{{ $t(item.text) }}</span>
                    </div>
                  </template>
                  <!-- change ticket -->
                  <template v-if="ticket.is_claimed">
                    <div
                      class="dialog-menu-item"
                      v-for="(item, index) in claimedTicketMenu"
                      :key="index"
                      @click="onClaimedActionClick(item.type)"
                    >
                      <span class="material-icons"> {{ item.icon }} </span>
                      <span>{{ $t(item.text) }}</span>
                    </div>
                  </template>
                </div>
              </v-dialog>
            </div>
          </template>

          <mem-button
            v-if="ticketButton === 'claim'"
            @click="onUnclaimedActionClick()"
            >{{ $t("claim_ticket") }}</mem-button
          >
          <mem-button
            v-if="ticketButton === 'transfer'"
            btn-type="secondary-dark"
            @click="onClaimedActionClick('change-event')"
            >{{ $t("transfer") }}</mem-button
          >
        </div>
      </div>
    </div>
    <div class="ticket-addons">
      <div class="ticket-tab" v-if="showSpartanTab">
        <div v-if="ticket.spartan_tab" class="inactive-item">
          <span class="material-icons item-icon"> done </span>
          <span>SpartanTab</span>
        </div>
        <template v-else>
          <div class="active-item" @click="spartanTabDialog = true">
            <span class="material-icons item-icon"> add </span>
            <span>{{ $t("add_spartan_tab") }}</span>
            <span class="material-icons-outlined item-icon"> info </span>
          </div>
          <upsell-details-dialog
            v-model="spartanTabDialog"
            type="spartanTab"
            :country="ticket.country"
            :currency="ticket.event.ticket_addons_currency"
            :price="ticket.event.spartan_tab_price_before"
            :buttonLink="upsellButtonLink"
          />
        </template>
      </div>
      <div v-if="showPhotoPackage">
        <div v-if="ticket.photo_package" class="inactive-item">
          <span class="material-icons item-icon"> done </span>
          <span>{{ $t("upsell_modal_photo_package_title") }}</span>
        </div>
        <template v-else>
          <div class="active-item" @click="photoPackageDialog = true">
            <span class="material-icons item-icon"> add </span>
            <span>{{ $t("add_photo_package") }}</span>
            <span class="material-icons-outlined item-icon"> info </span>
          </div>
          <upsell-details-dialog
            v-model="photoPackageDialog"
            type="photoPackage"
            :country="ticket.country"
            :currency="ticket.event.ticket_addons_currency"
            :price="ticket.event.photo_package_price_before"
            :buttonLink="upsellButtonLink"
          />
        </template>
      </div>
    </div>
    <div class="ticket-event">
      <div class="event-info-block">
        <div class="info-block-title">{{ $t("type") }}</div>
        <div class="info-block-text">{{ ticketRegChose }}</div>
      </div>
      <div class="event-info-block">
        <div class="info-block-title">{{ $t("start_time") }}</div>
        <div class="info-block-text">{{ ticket.start_time }}</div>
      </div>
    </div>
    <div class="ticket-team" v-if="ticket.team">
      <div class="team-info">
        <team-avatar :src="ticket.team.avatar" :size="20"></team-avatar>
        <div class="team-name">{{ ticket.team.name }}</div>
      </div>
      <span
        v-if="!selectMode && canRemoveTeam"
        @click="removeTicketTeam"
        class="material-icons"
      >
        close
      </span>
    </div>
  </div>
</template>
<script>
// import moment from "moment";
import { mapMutations } from "vuex";
import { includes } from "lodash";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "team-avatar": () => import("@/components/TeamAvatar.vue"),
    "upsell-details-dialog": () => import("./DialogUpsellDetails.vue"),
  },
  data: () => ({
    env: process.env.VUE_APP_ENV,
    dialogMenu: false,
    spartanTabDialog: false,
    photoPackageDialog: false,
  }),
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    ticketIndex: Number,
    selectMode: {
      type: Boolean,
      default: false,
    },
    eventDate: String,
  },
  computed: {
    canSelect() {
      let { team } = this.ticket;
      return !team && !this.isDisabled;
    },
    isDisabled() {
      let { status } = this.ticket;
      return status === "Transferred" || status === "Cancelled";
    },
    canRemoveTeam() {
      let { event } = this.ticket;
      return !event.is_passed;
    },
    showDefaultTitle() {
      let { is_mine, is_purchased_by_me, is_kids, is_claimed } = this.ticket;
      if (is_claimed) return false;

      return (is_mine && is_purchased_by_me) || is_kids;
    },
    ticketStatus() {
      let { is_claimed, status, processing } = this.ticket;

      if (processing) return `${this.$t("processing")}...`;
      if (status && this.isDisabled) return this.$t(status.toLowerCase());
      if (is_claimed) return this.$t("claimed");
      return this.$t("unclaimed");
    },
    ticketTitle() {
      let { attendee } = this.ticket;

      if (this.showDefaultTitle || !attendee)
        return `${this.$t("ticket")} ${this.ticketIndex}`;

      let { email, first_name, last_name } = attendee;

      if (!first_name && !last_name) return email;
      return `${first_name} ${last_name}`;
    },
    // in lowercase
    ticketRegChose() {
      let text = this.ticket?.reg_choice_name;
      if (!text) return;
      let str;
      if (text.indexOf("(") !== -1) str = text.substr(0, text.indexOf("("));
      else str = text;
      return str.toLowerCase();
    },
    addonTicket() {
      if (!this.ticketRegChose) return false;
      return (
        includes(this.ticketRegChose, "spectator") ||
        includes(this.ticketRegChose, "parking")
      );
    },
    showStartTime() {
      return !this.ticketButton || this.ticketButton === "more";
    },
    ticketButton() {
      let {
        is_claimed,
        is_purchased_by_me,
        is_mine,
        is_kids,
        transferable,
        country,
        event,
        pass_type,
        processing,
        status,
      } = this.ticket;

      if (this.selectMode || processing || this.isDisabled) return;

      // state based on event
      // TODO: Move country and status check to backend
      const TRANSFERABLE_COUNTRIES = ["US", "CA", "GB"];
      if (
        event.is_passed &&
        is_purchased_by_me &&
        transferable &&
        TRANSFERABLE_COUNTRIES.includes(country) &&
        status === "Purchased"
      )
        return "transfer";
      if (event.is_passed) return;
      //

      // state based on ticket data
      // is_claimed: true
      if (is_claimed && !is_purchased_by_me && is_mine) return;

      // is_claimed: false
      // claim
      if (!is_claimed && is_kids) return "claim";
      if (!is_claimed && !is_purchased_by_me && is_mine) return "claim";
      if (
        !is_claimed &&
        is_purchased_by_me &&
        is_mine &&
        pass_type === "non-transferrable"
      )
        return "claim";

      // add
      if (!is_claimed && is_purchased_by_me && is_mine) return "add";

      // event started
      if (is_claimed && event.is_started) return;

      // more
      return "more";
    },
    ticketOrgDomain() {
      let { country } = this.ticket;
      if (!country) return "https://tickets.spartan.com";

      let urlPart = "tickets";
      if (this.env !== "production") urlPart = "register-staging";

      if (country === "US") return `https://${urlPart}.spartan.com`;
      return `https://${urlPart}-${country.toLowerCase()}.spartan.com`;
    },
    unclaimedTicketMenu() {
      // let { country } = this.ticket;
      // const temporaryCountries = ["GB", "EU"];
      // const temporaryMenu = [
      //   { icon: "account_circle", text: "claim_yourself", type: "myself" },
      //   {
      //     icon: "add_circle",
      //     text: "add_athlete_manually",
      //     type: "someone_else",
      //   },
      // ];
      // if (temporaryCountries.includes(country)) return temporaryMenu;

      let menuItems = [
        { icon: "account_circle", text: "claim_yourself", type: "myself" },
        {
          icon: "add_circle",
          text: "add_athlete_manually",
          type: "someone_else",
        },
        { icon: "link", text: "send_to_someone_else", type: "send_ticket" },
      ];

      return menuItems;
    },
    claimedTicketMenu() {
      let menuItems = [
        {
          icon: "edit",
          text: "change_information",
          type: "change-information",
        },
        {
          icon: "arrow_circle_up",
          text: "upgrade_ticket",
          type: "upgrade-tickets",
        },
        { icon: "autorenew", text: "switch_ticket", type: "change-event" },
        { icon: "sync_alt", text: "transfer_ticket", type: "transfer-tickets" },
      ];
      return menuItems;
    },
    showSpartanTab() {
      let { status, is_claimed, is_purchased_by_me, spartan_tab, event } =
        this.ticket;

      if (this.addonTicket || !is_purchased_by_me) return false;

      if (spartan_tab) return true;

      if (!is_claimed) return false;
      if (event.is_passed) return false;
      if (status === "Transferred" || status === "Cancelled") return false;

      if (event.spartan_tab_price_before) return true;
      return false;
    },
    showPhotoPackage() {
      let { status, is_claimed, is_purchased_by_me, photo_package, event } =
        this.ticket;

      if (this.addonTicket || !is_purchased_by_me) return false;

      if (photo_package) return true;

      if (!is_claimed) return false;
      if (event.is_passed) return false;
      if (status === "Transferred" || status === "Cancelled") return false;

      if (event.photo_package_price_before) return true;

      // let offerDate = moment("2023-12-31", "YYYY-MM-DD");
      // let eventStartDate = moment("2024-01-01", "YYYY-MM-DD");
      // let isValidDate = eventStartDate.isAfter(offerDate);
      // if (!isValidDate) return false;
      return false;
    },
    upsellButtonLink() {
      let { order_hash } = this.ticket;
      return `${this.ticketOrgDomain}/my-account/change-order/${order_hash}/change-information`;
    },
  },
  methods: {
    ...mapMutations(["setCurrentTicket"]),
    onSelect() {
      if (!this.selectMode || this.isDisabled) return;
      this.$emit("select", this.ticket);
    },
    onUnclaimedActionClick(actionType) {
      let { event, barcode, is_kids } = this.ticket;
      this.setCurrentTicket(this.ticket);

      if (!actionType && is_kids) actionType = "kid";
      if (!actionType && !is_kids) actionType = "myself";

      if (actionType === "send_ticket") {
        this.dialogMenu = false;
        return this.$emit("sendTicket");
      }

      this.$router.push({
        name: "tickets",
        params: {
          id: event.id,
          barcode: barcode,
          type: actionType,
        },
      });
    },
    onClaimedActionClick(actionType) {
      let { order_hash } = this.ticket;
      window.open(
        `${this.ticketOrgDomain}/my-account/change-order/${order_hash}/${actionType}`,
        "_blank"
      );
    },
    removeTicketTeam() {
      this.setCurrentTicket(this.ticket);
      this.$emit("removeTeam");
    },
  },
};
</script>
<style lang="scss" scoped>
.tickets-list-item {
  min-height: 70px;
  margin-bottom: 8px;
  background-color: #121212;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 24px;
  padding-right: 22px;
  @media screen and (max-width: $tablet) {
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .ticket-main {
    display: grid;
    grid-template-columns: 40px 1fr;
    @media screen and (max-width: $tablet) {
      grid-template-columns: 34px 1fr;
    }
    .status-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      .material-icons {
        font-size: 22px;
      }
    }
    .ticket-data-grid {
      display: grid;
      grid-template-columns: 330px max-content 1fr;
      @media screen and (max-width: $tablet) {
        grid-template-columns: 1fr max-content;
        gap: 10px;
      }
      .ticket-attendee {
        overflow: hidden;
        .ticket-status {
          font-size: 12px;
          line-height: 10px;
          text-transform: uppercase;
          @media screen and (max-width: $tablet) {
            font-size: 11px;
          }
        }
        .is-claimed {
          color: #75b138;
        }
        .is-unclaimed {
          color: #ec5858;
        }
        .ticket-title {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: 0.0025em;
          margin-top: 7px;
          padding-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: $tablet) {
            white-space: nowrap;
            padding-right: 0;
          }
        }
        .is-dimmed {
          color: #585858;
        }
      }
      .ticket-event-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        text-transform: capitalize;
        @media screen and (max-width: $tablet) {
          display: none;
        }
        .event-reg-chose {
          width: 120px;
          padding-right: 10px;
        }
        .event-start-time {
          width: 100px;
          text-align: end;
        }
      }
      .ticket-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        button {
          height: 36px;
          padding: 0 19px;

          font-size: 12px;
          line-height: 15px;
          font-weight: 700;
          letter-spacing: 0.04em;
        }
        .context-menu {
          .btn-more {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-left: 10px;
            color: #656565;
          }
          @media screen and (max-width: $tablet) {
            display: none;
          }
        }
        .dialog-menu {
          display: none;
          @media screen and (max-width: $tablet) {
            display: inline-block;
          }
        }
      }
    }
  }
  .ticket-addons {
    margin-top: 5px;
    .inactive-item,
    .active-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 21px;
      .item-icon {
        font-size: 16px;
        margin-right: 6px;
      }
    }
    .active-item {
      text-transform: capitalize;
      font-weight: 700;
      @include cursorPointer;
      .material-icons-outlined {
        color: #979797;
        margin-right: 0;
        margin-left: 6px;
      }
    }
  }
  .ticket-tab {
    font-size: 14px;
    line-height: 21px;
  }
  .ticket-event {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    @media screen and (max-width: $tablet) {
      display: flex;
    }
    .event-info-block {
      &:last-child {
        text-align: end;
      }
      .info-block-title {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        letter-spacing: 0.0025em;
        text-transform: uppercase;
        color: #9d9d9d;

        margin-bottom: 2px;
      }
      .info-block-text {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        letter-spacing: 0.0025em;
        text-transform: capitalize;
      }
    }
  }
  .ticket-team {
    border-top: 1px solid #363636;
    margin-top: 18px;
    padding-top: 18px;
    padding-bottom: 4px;

    display: grid;
    grid-template-columns: 1fr max-content;
    .team-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 24px;
      .team-name {
        font-size: 14px;
        line-height: 17px;
        margin-left: 8px;
      }
    }
    .material-icons {
      color: #656565;
      @include cursorPointer;
    }
  }
  .ticket-addons,
  .ticket-event,
  .ticket-team {
    margin-left: 40px;
    @media screen and (max-width: $tablet) {
      margin-left: 34px;
    }
  }
}
.select-mode {
  @include cursorPointer;
}
.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.context-menu-body {
  background: #191919;
  border: 0.5px solid #4f4f4f;
  box-sizing: border-box;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  padding: 12px;

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    border-radius: 8px;
    height: 55px;
    min-width: 250px;

    transform: perspective(1px) translateZ(0);
    transition-duration: 0.5s;

    .item-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      color: #757575;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.08);
      transform: scale(1.03);
      cursor: pointer;

      .item-icon-wrapper {
        color: #ffffff;
      }
    }
  }
}
.dialog-menu-body {
  background-color: #ffffff;
  border-radius: 8px;
  color: #000000;
  padding: 25px 0 25px 25px;
  .dialog-menu-item {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    outline: none;
    .material-icons {
      color: #c0c0c0;
      margin-right: 14px;
    }
  }
}
</style>